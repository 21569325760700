import { StaticQuery, graphql } from "gatsby"
import React from "react"
import { Col, Container } from "react-bootstrap"
import LazyLoad from "react-lazyload"
import Fade from "react-reveal/Fade"
import CTA from "../components/common/CTA.js"
import IntegrationWheel from "../components/common/IntegrationWheel/IntegrationWheel.js"
import LatestResourcesV2 from "../components/common/Latest-Resources-Section/LatestResourcesV2.js"
import StackCards from "../components/common/Scrolling-container/StackCards.js"
import AccordionV3 from "../components/common/accordionV3.js"
import Buttons from "../components/common/button.js"
import Frame from "../components/common/frame.js"
import TestimonialV6 from "../components/common/testimonial-component/multiple-6.js"
import Navigation from "../components/navigation.js"
import "../styles/pages/home-2024.scss"

const Home24Static = props => {

  return (
    <div className="home-2024" style={{ background: "#F6F0E6" }}>
      <StaticQuery
        query={graphql`
          query home24Static {
            SuperOps {
              pages(where: { title: "HomeV7.0" }) {
                title
                linkTitle
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
                navigationBlock {
                  colors {
                    hex
                  }
                  where
                  dropdownHeader
                  navigationLinks {
                    heading
                    subText
                    link
                    image {
                      url
                    }
                    childFooter
                    childFooterLink
                    featureNavigationLinks {
                      heading
                      subText
                      link
                      icon {
                        url
                      }
                      iconBgColor {
                        css
                      }
                      childHeader
                    }
                  }
                }
                ctaBoxes {
                  type
                  theme
                  customBackgroundColor
                  heading {
                    html
                  }
                  description {
                    html
                  }
                  primaryButtonText
                  primaryButtonLink
                  secondaryButtonText
                  secondaryButtonLink
                  card {
                    cardName
                    title
                    subtext
                    slug
                  }
                }
                newField {
                  ... on SuperOps_Hero {
                    primaryButtonText
                    primaryButtonLink
                    secondaryButtonText
                    secondaryButtonLink
                    heroExcerptList
                    headingLine1
                    animAltText
                    heroImageMob {
                      url
                    }
                  }
                  ... on SuperOps_Testimonial {
                    testimonialDatas {
                      title
                      name
                      content
                      bgColor
                      image {
                        url
                      }
                    }
                    testimonialTitle
                    testimonialName
                    testimonialDescription
                    testimonialContent
                    testimonialLink
                    testimonialImage {
                      url
                    }
                  }
                  ... on SuperOps_Card {
                    title
                    heading
                    cardName
                    cardDescription
                    slug
                    links
                    text
                    subtext
                    image {
                      url
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const { navigationBlock, seo, ctaBoxes, newField } = page
              const heroSection = newField[7]
              const accordionData = navigationBlock[0]

              return (
                <div>
                  <Frame
                    title={seo?.title}
                    description={seo?.description}
                    ogType="website"
                    footerClass={"p-0"}
                    newButton
                  >
                    <header>
                      <Navigation page="Home" dskScroll={400} newButton />
                    </header>

                    <section className="hero-static prelative">
                      <div className="layout-box mx-auto">
                        <div className="bg-image">
                          <Container className="Layout-container d-flex flex-wrap">
                            <Col lg={6} className="left-col">
                              <div className="main-title mx-auto">
                                {heroSection.animAltText.map((item, idx) => {
                                  return (
                                    <div
                                      className={`prelative down-to-up-${idx +
                                        1} ${idx === 2 ? "diff" : ""}`}
                                    >
                                      <h1
                                        className={`heading font-white fw-800`}
                                      >
                                        {item}
                                      </h1>
                                    </div>
                                  )
                                })}
                              </div>
                              <div className="">
                                <p className="m-0 text-1 mt32 down-to-up-3 fw-600">
                                  {heroSection.headingLine1}
                                </p>
                                <p className="line down-to-up-3"></p>
                                <div className="text-2 down-to-up-3 fw-500 d-flex flex-wrap gap-1">
                                  {heroSection.heroExcerptList.map(
                                    (el, ind) => {
                                      return (
                                        <span
                                          className={`${
                                            ind === 1 ? "fw-800 font-white" : ""
                                          }`}
                                        >
                                          {el}
                                        </span>
                                      )
                                    }
                                  )}
                                </div>
                              </div>

                              <div className="buttons-wrapper">
                                <div className="buttons prelative d-flex gap-16 flex-wrap">
                                  <Buttons
                                    theme="primary-new white-new  down-to-up-4"
                                    link={heroSection.primaryButtonLink}
                                    text={heroSection.primaryButtonText}
                                  />
                                  <Buttons
                                    theme="secondary-new white-new down-to-up-4"
                                    link={heroSection.secondaryButtonLink}
                                    text={heroSection.secondaryButtonText}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col
                              lg={6}
                              className="right-col d-flex align-items-end d-mob"
                            >
                              <div className="img-wrapper mx-auto">
                                <img
                                  src={heroSection.heroImageMob.url}
                                  className="w-100 scaleFadeAnime"
                                  alt="hero-image"
                                />
                              </div>
                            </Col>
                          </Container>
                        </div>
                      </div>
                    </section>

                    <IntegrationWheel dataOne={newField[1]} dataTwo={newField[2]}/>

                    <section>
                      <AccordionV3 data={accordionData} />
                    </section>

                    <LazyLoad>
                      <section className="scroll-cards">
                        <Container className="Layout-container">
                          <div className="d-flex justify-content-between flex-wrap">
                            <Col lg={4}>
                              <h2 className="font-roboto fw-800 main-heading">
                                {newField[3].cardName}
                              </h2>
                            </Col>
                            <Col lg={7} className="right">
                              <StackCards
                                numberOfCards={4}
                                data={newField[3]}
                              />
                            </Col>
                          </div>
                        </Container>
                      </section>
                    </LazyLoad>

                    <LazyLoad>
                      <section className="badge-section">
                        <Container className="Layout-container">
                          <Fade bottom duration={1000}>
                            <h2 className="font-roboto fw-800 text-center mb30 heading mx-auto">
                              {newField[4].cardName}
                            </h2>
                          </Fade>
                          <Fade bottom duration={1000}>
                            <div className="d-flex justify-content-center flex-wrap badge-wrapper">
                              {newField[4].image.map(img => {
                                return (
                                  <div className="badge-img">
                                    <img src={img.url} className="w-100" />
                                  </div>
                                )
                              })}
                            </div>
                          </Fade>
                        </Container>
                      </section>
                    </LazyLoad>

                    <LazyLoad>
                      <Fade bottom duration={1000}>
                        <TestimonialV6
                          testimonial={newField[5].testimonialDatas}
                        />
                      </Fade>
                    </LazyLoad>

                    <section className="cta normal-cta">
                      <LazyLoad>
                        <Fade bottom duration={1000}>
                          <CTA
                            data={[ctaBoxes[0]]}
                            className="Layout-container"
                            lgLeft={6}
                            lgRight={3}
                            newButton
                            newDesign
                          />
                        </Fade>
                      </LazyLoad>
                    </section>

                    <section className="">
                      <Container className="Layout-container">
                        <LazyLoad>
                          <LatestResourcesV2 leftColData={newField[6]} />
                        </LazyLoad>
                      </Container>
                    </section>

                    <section className="cta second-cta">
                      <LazyLoad>
                        <CTA
                          data={[ctaBoxes[1]]}
                          bgPattern
                          className="Layout-full"
                          maxDescriptionWidth="475px"
                          maxBoxWidth="100%"
                          maxRowWidth="1176px"
                          lgLeft={5}
                          lgRight={7}
                          newButton
                          newDesign
                        />
                      </LazyLoad>
                    </section>
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </div>
  )
}

export default Home24Static